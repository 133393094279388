<template>
  <MainLayout>
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Sync PDDikti</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button class="btn btn-success ml-2" @click="GetDetailBiodataDosen">
            <BatteryChargingIcon class="h-4" /> Get PTK
          </button>
          <button
            v-if="countdosen > 0 && loading === false"
            class="btn btn-default ml-2"
            @click="SyncData"
          >
            <SaveIcon class="h-4" /> Sync Data
          </button>
        </div>
      </div>

      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <div>
          <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
          <AlertFailed v-if="alertFailed" :message="messageAlert" />
        </div>
        <div
          v-if="loading"
          class="relative bg-white w-full h-full z-100 flex justify-center items-center z-50"
        >
          <div class="opacity-100">
            <LoadingSpinner :is-loading="loading" :use-state="useState" />
          </div>
        </div>

        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <table
            v-if="countdosen > 0 && loading === false"
            class="table table--sm"
          >
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  #
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  NIDN
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  NAMA
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  NIK
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Jenis Sdm
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Status Keaktifan
                </th>
                <th
                  class="border-b-2 dark:border-dark-5 whitespace-nowrap bg-gray-200 text-gray-700"
                >
                  Sync
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dsn, key) in datadosen" :key="dsn.id_dosen">
                <td class="border-b dark:border-dark-5">{{ ++key }}</td>
                <td class="border-b dark:border-dark-5">{{ dsn.nidn }}</td>
                <td class="border-b dark:border-dark-5">
                  {{ dsn.nama_dosen }}
                </td>
                <td class="border-b dark:border-dark-5">
                  {{ dsn.nik }}
                </td>
                <td class="border-b dark:border-dark-5">
                  {{ dsn.nama_jenis_sdm }}
                </td>
                <td class="border-b dark:border-dark-5">
                  {{ dsn.nama_status_aktif }}
                </td>
                <td
                  class="border-b dark:border-dark-5 bg-gray-200 text-gray-700"
                >
                  <span
                    v-for="(item, key) in syncResult[dsn.id_dosen]"
                    :key="key"
                    class="text-sm text-gray-600"
                  >
                    [<b>{{ key }}</b
                    >] {{ item }}<br />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="overflow-x-auto scrollbar-hidden"></div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <template #rightside>
      <SyncFeeder class="mt-8" />
    </template>
  </MainLayout>
</template>

<script>
import { defineComponent } from "vue";
import MainLayout from "@/layouts/main/MainLayout";
import { wspddikti, wssister } from "@/services/wspddikti";
import SyncFeeder from "@/components/utility/SyncFeeder";
import { dosen } from "@/services/models";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import LoadingSpinner from "@/components/loading-spinner/Main";
export default defineComponent({
  components: {
    MainLayout,
    SyncFeeder,
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  data() {
    return {
      datadosen: null,
      countdosen: null,
      statuscek: [],
      loading: false,
      syncMsg: "",
      syncStatus: null,
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      syncResult: [],
      useState: false,
    };
  },
  methods: {
    GetDetailBiodataDosen() {
      this.loading = true;
      this.syncStatus = null;
      wspddikti
        .GetData({
          act: "DetailBiodataDosen",
          filter: "",
        })
        .then((response) => {
          if (response.data?.error_code?.toString() === "0") {
            this.countdosen = response.data.data.length;
            this.datadosen = response.data.data;
            this.datadosen.forEach((dsn) => {
              this.GetJabfungSister(dsn.id_dosen);
              this.GetKepangkatan(dsn.id_dosen);
              this.GetInpassing(dsn.id_dosen);
            });
          } else {
            this.alertFailed = true;
            this.syncStatus = false;
            this.messageAlert =
              response.data?.error_desc === ""
                ? "Data tidak ditemukan"
                : response.data?.error_desc;
          }
        });
    },
    GetJabfungSister(id) {
      wssister
        .GetData("jabatan_fungsional", {
          id_sdm: id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.datadosen.map((items) => {
              if (items.id_dosen == id) {
                items.jabfung = res.data;
              }
            });

            res.data.forEach((jabfung) => {
              wssister
                .GetData("jabatan_fungsional/" + jabfung.id, {})
                .then((res2) => {
                  res.data.map((itemss) => {
                    if (itemss.id == jabfung.id) {
                      itemss.id = res2.data;
                    }
                  });
                })
                .finally(() => {
                  this.loading = false;
                });
            });
          }
        });
    },
    GetKepangkatan(id) {
      wssister
        .GetData("kepangkatan", {
          id_sdm: id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.datadosen.map((items) => {
              if (items.id_dosen == id) {
                items.kepangkatan = res.data;
              }
            });

            res.data.forEach((kepangkatan) => {
              wssister
                .GetData("kepangkatan/" + kepangkatan.id, {})
                .then((res2) => {
                  res.data.map((itemss) => {
                    if (itemss.id == kepangkatan.id) {
                      itemss.id = res2.data;
                    }
                  });
                })
                .finally(() => {
                  this.loading = false;
                });
            });
          }
        });
    },
    GetInpassing(id) {
      wssister
        .GetData("inpassing", {
          id_sdm: id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.datadosen.map((items) => {
              if (items.id_dosen == id) {
                items.inpassing = res.data;
              }
            });

            res.data.forEach((inpassing) => {
              wssister
                .GetData("inpassing/" + inpassing.id, {})
                .then((res2) => {
                  res.data.map((itemss) => {
                    if (itemss.id == inpassing.id) {
                      itemss.id = res2.data;
                    }
                  });
                })
                .finally(() => {
                  this.loading = false;
                });
            });
          }
        });
    },
    SyncData() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.syncStatus = true;
      this.loading = true;

      dosen.SDM.syncData("import_jabfung", { ...this.datadosen }).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.status_code === 200) {
              this.alertSuccess = true;
              this.messageAlert = response.data?.message;
              this.syncStatus = response.data?.status;
              if (response?.data?.data) {
                this.syncResult = response?.data?.data;
              }
            } else {
              this.alertFailed = true;
              this.messageAlert = response.data?.message;
            }
          }
        }
      );

      this.loading = false;
    },
  },
});
</script>
